import React from 'react';

const fullName = 'Aruna Krishnakumar';

const copyListTiffanyWest = [
    {
        type: 'image',
        content: {
            src: '/Deeper-dive-callen-lorde.png',
            src: '/Deeper-dive-callen-lorde.png',
            alt: 'Join us at a National HIV Testing Day event by Callen-Lorde',
            caption: ''
        }
    },
    {
        type: 'copy-block',
        content: (
            <>
                <h3 class='title tiffany-west'>
                    A conversation with Aruna Krishnakumar, Managing Director of Adolescent & Mobile
                    Health, Callen-Lorde
                </h3>
                <p>
                    HIV testing is the only way for a person to know if they are living with HIV and
                    the first step toward maintaining a healthy life and helping prevent
                    transmission of HIV.<sup>1</sup> Yet, as of 2021, only about one-third (37%) of
                    US adults ages 18-64 reported ever receiving an HIV test.<sup>2,3</sup>{' '}
                    Furthermore, an estimated 40% of new HIV infections are transmitted by people
                    who do not know they have the virus.<sup>1</sup> Barriers such as fear, stigma,
                    and perceived lack of risk prevent many people from seeking an HIV test.
                    <sup>4</sup>
                </p>
                <p>
                    Each year on June 27, National HIV Testing Day (NHTD) is recognized as a day to
                    encourage people to get tested for HIV, know their status, and get linked to HIV
                    care and treatment.<sup>5</sup> This NHTD, the New York City Department of
                    Health and Callen-Lorde Community Health Center are bringing HIV testing to the
                    community at a live event in New York City, sponsored by Gilead. “Press Play on
                    Knowing Your Status: Join us at a National HIV Testing Day event by
                    Callen-Lorde” is leveraging collective action to drive greater impact. We sat
                    down with Callen-Lorde's Aruna Krishnakumar, Managing Director of Adolescent &
                    Mobile Health, to talk about this event and the power of intersectional
                    collaborations.
                </p>
                <p class='question'>
                    <strong>
                        Tell us about the upcoming National HIV Testing Day event powered by
                        Callen-Lorde
                    </strong>
                </p>
                <p>
                    Callen-Lorde is coordinating the first New York City-led National HIV Testing
                    Day event since 2019. “Press Play on Knowing Your Status: Join us at a National
                    HIV Testing Day event by Callen-Lorde” is an all-day, all-ages event set to take
                    place on June 27 in Union Square in New York City. With over 35 organizations
                    participating, we will have music and entertainment, free health screenings,
                    health promotion, and rapid HIV testing.
                </p>
                <p>
                    The upcoming NHTD event is about prioritizing one’s health and well-being,
                    making healthier decisions, and knowing one’s HIV status. We want to reach as
                    many individuals and communities as we can to educate them about HIV and the
                    importance of getting tested. Union Square is a heavily trafficked public space,
                    and by holding the event here, we hope to help normalize HIV testing, encourage
                    conversations about it, and make HIV testing accessible.
                </p>
            </>
        )
    },
    {
        type: 'blockquote',
        content:
            '“HIV continues to disproportionately impact marginalized communities, black and brown communities, TGNB communities,&nbsp;and young people and older people. We have to address structural barriers and inequalities in order to address health outcomes. National HIV Testing Day is just one intervention of many to increase access and break down barriers.”',
        author: fullName,
        bottomContent: 'Managing Director of Adolescent & Mobile Health, Callen-Lorde'
    },
    {
        type: 'copy-block',
        content: (
            <>
                <p>
                    <strong>
                        How do you think, as a community, we can help more people get tested for
                        HIV, know their status, and talk to a doctor about next steps for them?
                    </strong>
                </p>
                <p>
                    There is still so much stigma around HIV and a misperception that it only
                    affects specific communities.<sup>6</sup> By normalizing HIV testing as part of
                    routine health screening and providing it in the most accessible ways—as you’re
                    getting off the train, walking through the farmers’ market—we hope to encourage
                    individuals to know their status. By knowing one’s status, there is so much you
                    can do to take care of your own health as well as help protect others.
                    <sup>1</sup>
                </p>
                <p>
                    <strong>
                        What strategies/interventions have you found to be the most impactful in
                        terms of engaging people in HIV testing?
                    </strong>
                </p>
                <p>
                    Our strategies evolve with the needs of the communities we serve. We start by
                    offering HIV testing to everyone we engage in our clinics. We don’t make
                    assumptions about who needs testing. Our interventions need to be tailored to
                    different generations, be accessible in multiple languages—we cannot assume what
                    the face of HIV looks like. The CDC recommends everyone ages 13 to 64 get tested
                    for HIV at least once as part of routine healthcare,<sup>1</sup>&nbsp; and we
                    believe in making HIV testing a regular practice, as part of checking up on your
                    health.
                </p>
                <p>
                    We provide HIV education and testing through community engagement, meeting
                    people where they are. We attend community events such as health fairs, house
                    balls, high school and college fairs, among other community events, to reach as
                    many individuals as possible. We also leverage our mobile unit to provide full
                    health services in the community, helping to reduce some of the barriers that
                    keep people from going into clinics.
                </p>
                <p>
                    Living in a digital age, we also use social media to help raise HIV awareness
                    and encourage testing. Our most recent video campaign, SMASH (Social Media and
                    Sexual Health), features our community’s most popular social media influencers
                    to educate on HIV pre-exposure prophylaxis (PrEP).
                </p>
            </>
        )
    },
    {
        type: 'copy-block',
        content: (
            <>
                <p>
                    <strong>
                        Why are community collaborations like this essential to driving change in
                        the HIV epidemic?
                    </strong>
                </p>
                <p>
                    It is so important for organizations committed to helping end the HIV/AIDS
                    epidemic to come together to create change. Intersectional collaborations like
                    this strengthen our ability to engage communities in HIV education, testing, and
                    care. By leveraging all of our resources and creativity, we can help drive
                    greater change together.
                </p>
            </>
        )
    },
    {
        type: 'blockquote',
        content:
            '“HIV/AIDS organizing, starting with the legacy of ACT-UP, is rooted in community collaboration, and we know that the only way to fight this epidemic is through cross-sectional partnerships, mutual support,&nbsp;and community building. To quote our namesake, Audre Lorde,&nbsp; ’Without community, there is no liberation’.”<sup>7</sup> ',
        author: fullName,
        bottomContent: 'Managing Director of Adolescent & Mobile Health, Callen-Lorde'
    },
    {
        type: 'copy-block',
        content: (
            <>
                <p>
                    <strong>
                        About Callen-Lorde<sup>7</sup>
                    </strong>
                </p>
                <p>
                    Callen-Lorde is a community health center and global leader in LGBTQ healthcare.
                    Callen-Lorde Community Health Center provides sensitive, quality healthcare and
                    related services targeted to New York’s lesbian, gay, bisexual, and transgender
                    communities—in all their diversity—regardless of ability to pay. To further this
                    mission, Callen-Lorde promotes health education and wellness and advocates for
                    LGBTQ health issues.
                </p>
                <div class='featured-img text-center mt-4 mb-4'>
                    <img
                        class='profile img-fluid'
                        src='/Deeper-dive-about-callen-lorde.png'
                        alt='Text reads Callen-Lorde. Photo of people on a float at a pride parade'
                    />
                </div>
                <p>
                    <strong>About Press Play</strong>
                </p>
                <p>
                    “Press Play” is more than a traditional HIV testing awareness campaign. It
                    motivates people to get tested for HIV and serves as a catalyst connecting them
                    to care. Gilead strives to inspire people in the communities hardest hit by HIV
                    to feel confident in knowing their HIV status, to be their true selves, and to
                    move forward in their lives. Regardless of HIV status, “Press Play” provides
                    encouragement to take the necessary steps to treat or help prevent HIV.
                </p>
                <div class='featured-img text-center mt-4 mb-4'>
                    <img
                        class='profile img-fluid'
                        src='/Deeper-dive-about-press-play.png'
                        alt='Text reads press play. Photo of men in a car smiling'
                    />
                </div>
            </>
        )
    }
];

export default copyListTiffanyWest;
