// import * as React from "react";
import ArticleLayout from '../articles/article-layout';
import copyList from './copyList';
import References from '../global/references.js';

import * as React from 'react';
import { Component } from 'react';

export default class Content extends Component {
    render() {
        return (
            <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
                <ArticleLayout
                    page={this.props.page}
                    copyList={copyList}
                    ariaLabel='Group of people sitting on a stoop and laughing'
                    // videoStillAlt='Click to play the EHE Masterclass Video'
                    subheading='Testing and Prevention'
                    heading='A National HIV Testing Day Event Powered by Callen-Lorde and Sponsored by Gilead'
                />
                <References page='callen-lorde' />
            </div>
        );
    }
}
